<style lang="less" scoped>
	img {
		width: 100%;
		height: 100%;
	}

	.paging {
		height: 100px;
		position: relative;
	}

	.el-pagination {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.w {
		width: 1200px;
		margin: 0 auto;
		padding-bottom: 30px;
	}

	i {
		color: #0090f0;
	}

	.test {
		margin-top: 30px;
		background-color: #fff;
	}

	.header_box {
		position: relative;
		height: 80px;
	}

	.header {
		position: absolute;
		top: 0;
		left: 0;
		width: 1200px;
		padding: 0 50px;
		box-sizing: border-box;
		// justify-content: space-around;
		height: 80px;
		font-size: 18px;
		font-weight: bold;
		color: #333333;
		box-shadow: 0px 4px 6px 0px rgba(0, 82, 137, 0.06);

		.H-item {
			cursor: pointer;
			margin-right: 70px;

			.img {
				width: 28px;
				height: 26px;
				margin-right: 10px;
			}
		}

		.Hitem1 {
			color: #0090f0;
		}
	}

	.tabs {
		padding: 0 20px 20px;

		.subject {
			margin-top: 10px !important;
		}

		.exam {
			margin-top: 30px;
			font-size: 16px;
			font-weight: 500;

			.exam_left {
				height: 30px;
				line-height: 30px;
				flex-shrink: 0;
				font-weight: bold;
			}

			.line {
				width: 1px;
				height: 20px;
				margin-top: 5px;
				background-color: #dddddd;
				margin-left: 20px;
				margin-right: 30px;
			}

			.exam_list {
				flex-wrap: wrap;

				div {
					cursor: pointer;
					padding: 0 15px;
					margin-right: 20px;
					margin-bottom: 10px;
					height: 30px;
					line-height: 30px;
				}

				.active1 {
					text-align: center;
					background: rgba(0, 144, 240, 0.1);
					border-radius: 15px;
					color: #0090f0;
				}
			}
		}
	}

	.header2 {
		padding: 0 30px;
		margin: 20px 0;
		justify-content: space-between;
		height: 57px;
		background: #ffffff;
		font-size: 16px;
		box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);

		.header2Left {
			div {
				cursor: pointer;
				margin-right: 50px;
			}

			.active3 {
				color: #0090f0;
			}
		}

		.header2Right {
			.only {
				div {
					margin-left: 10px;
				}
			}

			.num {
				margin-left: 50px;

				span {
					color: #f13232;
				}
			}
		}
	}

	.List {
		// height: 600px;
		// background-color: aqua;
		flex-wrap: wrap;
		margin-top: 30px;

		.list_item {
			position: relative;
			top: 0;
			cursor: pointer;
			background-color: #fff;
			width: 280px;
			height: 270px;
			// padding: 15px 17px 0;
			margin-right: 26px;
			margin-bottom: 30px;
			box-sizing: border-box;
			transition: all 0.5s;
			border-radius: 6px;
			overflow: hidden;

			&:nth-child(4n) {
				margin-right: 0 !important;
			}

			&:hover {
				box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
				position: relative;
				top: -10px;
				// .itemImg {
				//   transform: scale(1.2);
				//   transition: transform 1s ease 0s;
				// }
			}
		}

		// .common {
		//   background-color: #fff;
		//   width: 280px;
		//   height: 270px;
		//   padding: 15px 17px 0;
		//   margin-right: 24px;
		//   box-sizing: border-box;
		//   &:nth-child(4n){
		//     margin-right: 0 !important;
		//   }
		// }
		.itemOne {
			.itemTop {
				height: 190px;
				box-sizing: border-box;

				// background-color: sienna;
				// .img_top {
				//   height: 150px;
				//   overflow: hidden;
				// }
				.itemImg {
					// width: 246px;
					width: 100%;
					height: 170px;
				}

				.title {
					margin: 15px 17px 0 17px;
					font-size: 18px;
					font-weight: 500;
					color: #333333;
				}
			}
		}

		.itemBtn {
			justify-content: space-between;
			height: 65px;
			// background-color: slateblue;
			color: #f13232;
			margin: 10px 17px;

			.btnLeft {
				color: #999999;

				font-size: 12px;

				span {
					color: #f13232;
				}
			}

			.btnRight {
				font-size: 22px;

				span {
					font-size: 14px;
				}
			}
		}
	}

	.type-img {
		width: 246px;
		height: 150px;
		background-color: skyblue;
	}

	.infoTop {
		// height: 190px;
		position: relative;
		height: 190px;
		box-sizing: border-box;
		// background-color: rosybrown;
		border-bottom: 1px solid #f2f2f2;

		.title {
			// height: 60px;
			font-size: 20px;
			font-weight: 500;
			color: #333333;
			line-height: 30px;
		}

		.L-tags {
			height: 100px;

			// background-color: rgba(199, 0, 0, 0.34);
			div {
				display: inline-block;
				margin-top: 10px;
				margin-right: 10px;
				height: 24px;
				line-height: 24px;
				padding: 0 10px;
				font-size: 12px;
				color: #0090f0;
				background-color: rgba(0, 144, 240, 0.1);
			}
		}

		.period_box {
			height: 12px;

			.period {
				position: absolute;
				bottom: 15px;
				font-size: 12px;
				font-weight: 400;
				color: #999999;
			}
		}
	}

	.infoBot {
		height: 65px;
		box-sizing: border-box;
		// background-color: seagreen;
		justify-content: space-between;
		font-size: 12px;
		color: #999999;

		span {
			color: #f13232;
		}

		.infoBot-right {
			width: 100px;
			height: 30px;
			line-height: 30px;
			color: #f13232;
			text-align: center;
			font-size: 14px;
			font-weight: 500;
			border: 1px solid #f13232;
			border-radius: 15px 15px 15px 15px;
			box-sizing: border-box;
		}
	}

	.none {
		color: #999999;
		justify-content: center;
		font-size: 30px;
		height: 400px;
		background-color: #fff;

		img {
			width: 230px;
			height: 150px;
		}
	}

	.iteTwo {
		display: none;
	}
</style>
<template>
	<div>
		<div class="w">
			<div class="test">
				<div class="header_box">
					<div class="flex header">
						<div class="flex1 H-item" :class="{ Hitem1: item.checked == 1 }"
							v-for="(item, index) in initData" :key="index" @click="change(item.id)">
							<!-- <div class="img">
                <img :src="active==index?item.img2 :item.img1 " />
              </div>-->
							<div class="img">
								<img :src="item.icon" />
							</div>
							<div class="text">{{ item.title }}</div>
						</div>
					</div>
				</div>

				<div class="tabs">
					<div class="flex exam">
						<div class="exam_left">考试</div>
						<div class="line"></div>
						<div class="flex1 exam_list">
							<div :class="{ active1: active1 == 0 }" @click="change1()">
								全部
							</div>
							<div :class="{ active1: item.checked == 1 }" @click="change(item.id)"
								v-for="(item, index) in tab1.son" :key="index">
								{{ item.title }}
							</div>
						</div>
					</div>
					<div class="flex exam subject">
						<div class="exam_left">科目</div>
						<div class="line"></div>
						<div class="flex1 exam_list">
							<div :class="{ active1: active2 == 0 }" @click="change2()">
								全部
							</div>
							<div :class="{ active1: item.checked == 1 }" @click="change(item.id)"
								v-for="(item, index) in tab2.son" :key="index">
								{{ item.title }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="flex1 header2">
				<div class="flex1 header2Left">
					<div :class="{ active3: active3 == 1 }" @click="change3(1)">全部</div>
					<div :class="{ active3: active3 == 2 }" @click="change3(2)">
						销量
						<i v-if="variation == 1" class="el-icon-top"></i>
						<i v-if="variation == 2" class="el-icon-bottom"></i>
					</div>
					<div :class="{ active3: active3 == 3 }" @click="change3(3)">
						时间
						<i v-if="variation1 == 1" class="el-icon-top"></i>
						<i v-if="variation1 == 2" class="el-icon-bottom"></i>
					</div>
				</div>
				<div class="flex1 header2Right">
					<div class="flex1 only">
						<el-switch v-model="value" @change="change4(value)" active-color="#0090F0"
							inactive-color="#ccc"></el-switch>
						<div>仅看系列课程</div>
					</div>
					<div class="num">
						总有
						<span>{{ count }}</span>门课程
					</div>
				</div>
			</div>
			<!-- 列表 -->
			<div class="flex List">
				<div class="list_item" v-for="(item, index) in culum" :key="index">
					<div class="common itemOne" @click="goDetail($event, item.type, item)">
						<div class="itemTop">
							<div class="itemImg">
								<img :src="item.alias_litpic" alt srcset />
							</div>
							<div class="title">{{ item.alias_name }}</div>
						</div>
						<div class="flex1 itemBtn">
							<div class="btnLeft">
								已有
								<span>{{ item.alias_purchase_num }}</span>人学习
							</div>
							<div class="btnRight">
								<span>¥</span>
								{{ item.alias_price }}
							</div>
						</div>
					</div>
					<div v-if="item.type == 2" class="common iteTwo" @click="goDetail($event, item.id, item.type)">
						<div class="infoTop">
							<div class="type-img">
								<img src="https://image.hnzye.com/241d71f1f198103b/453985d4df6a5b54.jpg" />
							</div>
							<!-- <div class="title">{{ item.alias_name }}</div>
              <div class="L-tags">
                <div v-for="(item1, index1) in item.label" :key="index1">
                  {{ item1 }}
                </div>
              </div> -->
							<div class="period_box">
								<div class="period">{{ item.count }}课时</div>
							</div>
						</div>
						<div class="flex1 itemBtn">
							<div class="btnLeft">
								已有
								<span>{{ item.alias_purchase_num }}</span>人学习
							</div>
							<div class="btnRight">
								<span>¥</span>
								{{ item.alias_purchase_num }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="paging" v-if="culum.length != 0">
				<el-pagination background @current-change="handleCurrentChange" :current-page.sync="currentPage"
					:page-size="limit" layout="total, prev, pager, next, jumper" :total="count"></el-pagination>
			</div>
			<div class="none flex1" v-if="culum.length == 0">
				<img src="../../assets/img/course/noData.png" />
			</div>
		</div>
	</div>
</template>
<script>
	import Bus from "../../bus.js";
	import {
		courseIndex
	} from "../../assets/js/course";
	export default {
		data() {
			return {
				// 分类id
				categryId: 0,
				limit: 16,
				order: "",
				key: "",
				offset: 1,
				//
				active: 0,
				active1: 0,
				active2: 0,
				active3: 1,
				// img地址
				imgUrl: "http://v2.hnzye.cn",
				// 顶部选择
				initData: [],
				// 考试选择
				tab1: [],
				// 科目选择
				tab2: [],
				// 课程列表
				culum: [],
				// 总共多少课
				count: 0,
				// 开关 系列课
				value: false,
				// 销量的排序
				variation: 0,
				// 时间的排序
				variation1: 0,
				currentPage: 1,
				series: 0,
				search: "",
			};
		},
		created() {},
		destroyed() {
			// window.localStorage.setItem("active11", -1);
		},
		mounted() {
			Bus.$on("msg", (e) => {
				this.search = e;
				this.init();
			});
			this.search = this.$route.query.search;
			this.categoryId = this.$route.query.id2 || 0;
			this.init();
		},
		methods: {
			init() {
				courseIndex({
					siteId: window.localStorage.getItem("siteId"),
					category: this.categoryId || "",
					order: this.order || "",
					key: this.key || "",
					limit: this.limit || "",
					offset: this.offset || "",
					series: this.series || "",
					search: this.search || "",
				}).then((res) => {
					// console.log(res);
					let category = res.data.category;
					// console.log(category);
					this.initData = category;
					this.count = res.data.count;
					this.culum = res.data.culum;

					// 获取考试数据 以及激活的选项
					let i = 0;
					category.forEach((item) => {
						if (item.checked == 1) {
							this.tab1 = item;
							item.son.forEach((item1) => {
								if (item1.checked == 1) {
									this.active1 = item1.id;
									// console.log(1111);
								} else {
									i++;
									// console.log(44444);
								}
							});
						}
					});
					if (i == this.tab1.son.length) {
						this.active1 = 0;
						// console.log(3333);
					}
					// 点击考试的全部的时候 科目展示第一项的全部
					if (this.active == 0) {
						this.tab2 = this.tab1.son[0];
						this.active2 = 0;
						// console.log(55555);
					}
					// if (window.localStorage.getItem("active11") >= 0) {
					//   console.log(2222);
					//   this.active1 = window.localStorage.getItem("active11");
					// }
					if (this.active1 !== 0) {
						this.tab1.son.forEach((x) => {
							if (x.id == this.active1) {
								x.checked = 1;
							} else {
								x.checked = 0;
							}
						});
					} else {
						this.tab2 = this.tab1.son[0];
						this.active2 = 0;
						// console.log(55555);
					}
					// 获取科目数据 以及激活的选项
					let i1 = 0;
					this.tab1.son.forEach((item) => {
						if (item.checked == 1) {
							this.tab2 = item;
							// console.log(item.son);
							item.son.forEach((item1) => {
								if (item1.checked == 1) {
									this.active2 = item1.id;
								} else {
									i++;
								}
							});
						}
					});
					// console.log(this.tab2.son);
					if (i1 == this.tab2.son.length) {
						this.active2 = 0;
					}
				});
			},
			change(id) {
				this.search = "";
				// console.log(id);
				this.series = 0;
				this.value = false;
				this.offset = 1;
				this.categoryId = id;
				this.currentPage = 1;
				this.init();
				// window.localStorage.setItem("active11", -1);
			},
			change1() {
				this.search = "";
				this.currentPage = 1;
				this.series = 0;
				this.value = false;
				this.offset = 1;
				this.categoryId = this.tab1.id;
				this.init();
			},
			change2() {
				this.search = "";
				this.series = 0;
				this.value = false;
				this.offset = 1;
				this.categoryId = this.tab2.id;
				this.currentPage = 1;
				this.init();
			},
			change3(i) {
				this.search = "";
				this.series = 0;
				this.value = false;
				this.offset = 1;
				this.active3 = i;
				this.currentPage = 1;
				if (i == 1) {
					this.order = "";
					this.key = "";
					this.variation = 0;
					this.variation1 = 0;
				} else if (i == 2) {
					this.variation1 = 0;
					this.order = "alias_purchase_num";
					if (this.variation == 0 || this.variation == 2) {
						this.variation = 1;
						this.key = "asc";
					} else if (this.variation == 1) {
						this.variation = 2;
						this.key = "desc";
					}
				} else if (i == 3) {
					this.variation = 0;
					this.order = "create_at";
					if (this.variation1 == 0 || this.variation1 == 2) {
						this.variation1 = 1;
						this.key = "asc";
					} else if (this.variation1 == 1) {
						this.variation1 = 2;
						this.key = "desc";
					}
				}
				this.init();
			},
			change4(val) {
				this.search = "";
				this.currentPage = 1;
				// console.log(val);
				this.offset = 1;
				if (val) {
					this.series = 1;
				} else {
					this.series = 0;
				}
				this.init();
			},
			goDetail(e, type, item) {
				// console.log(this.active1);
				// console.log(this.active2);
				// window.localStorage.setItem("active11", this.active1);
				// window.localStorage.setItem("active22", this.active2);
				let id = 0;
				if (type == 1) {
					id = item.culum_id;
				} else {
					id = item.id;
				}
				this.$router.push({
					path: "/detail",
					query: {
						id: id,
						active2: type
					}
				});
			},
			handleCurrentChange(val) {
				this.search = "";
				this.offset = val;
				this.init();
			},
		},
	};
</script>
